<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Nombre
    </template>
    <template v-slot:subtitle>
      Añadir usuario Conalot
    </template>

    <template #content>
      <div class="filters">

        <!--          Name            -->
        <div class="field">
          <span class="p-float-label">
            <InputText id="name" type="text" v-model="name" class="form-input"/>
            <label for="name">Nombre</label>
          </span>
        </div>
        <!--          Email           -->
        <div class="field">
          <span class="p-float-label">
            <InputText id="email" type="text" v-model="email" class="form-input"/>
            <label for="email">Correo Electrónico</label>
          </span>
        </div>
        <!--          Password        -->
        <div class="field">
          <span class="p-float-label">
            <InputText id="pass"
                       type="text"
                       v-model="pass"
                       class="form-input"
                       ref="pass"
                       v-on:keyup="hidePass($event)"
            />
            <label for="pass">Contraseña</label>
          </span>
        </div>

      </div>


    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-end">
        <Button label="Siguiente"
                @click="nextPage()"
                :disabled="this.name && this.email && this.password ? disabled : ''"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

  <Toast/>
</template>

<script>


import UserService from "@/service/backend/UserService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Step1",
  mixins: [generalMixin],

  created() {
    this.userService = new UserService;
  },
  mounted() {
    this.name = null;
    this.email = null;
    this.password = null;

  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/usuarios-conalot/agregar-1'
        },
        {
          label: 'Nivel de Acceso',
          to: '/usuarios-conalot/agregar-2'
        },
      ],
      name: null,
      email: null,
      password: null,
      pass: null,
      passArr: [],

      pageIndex: 0,
      roleId: null,
      userService: null,
    }
  },

  methods: {
    hidePass(input) {
      if (input.key === 'Backspace') {
        this.passArr.pop();
      } else {
        this.passArr.push(input.key);
      }

      this.password = this.passArr.toString();
      this.pass = "*".repeat(this.passArr.length);
    },
    async nextPage() {
      const existingUser = await this.checkEmailDoesntExist();
      if (!existingUser) {
        this.$store.commit('setUser', {
          name: this.name,
          email: this.email,
          password: this.password,
          id_comercializador: this.$store.getters.getUser.id_comercializador,
          id_banca: this.$store.getters.getUser.id_banca,
          id_distribuidor: this.$store.getters.getUser.id_distribuidor,
        });
        this.$router.push({name: 'usuarios-conalot/agregar-2',})
      } else {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Este correo ya existe. Por favor seleccione otro.',
          life: 10000
        });
        this.$router.push({name: 'usuarios-conalot/agregar-1',})
      }
    },
    async checkEmailDoesntExist() {
      return await this.userService.exists({email: this.email})
    },

    async saveUser() {
      const user = {
        name: this.name,
        email: this.email,
        status_id: 2,
      };
      try {
        // Encrypt password here
        user.password = this.encodePassword(this.password);

        if (user.id) {
          await this.userService.updateConalot(user);
          this.userDialog = false;
          this.$emit('close-reload');
        } else {
          const newUser = await this.userService.createConalot(user);

          if (newUser.error) {
            this.$toast.add({
              severity: 'error',
              summary: '',
              detail: 'No se pudo crear/editar el usuario | ' + newUser.error,
              life: 10000
            });
          } else {
            this.$toast.add({severity: 'success', summary: '', detail: `Usuario ${newUser.name} creado`, life: 3000});
            this.$router.push('/usuarios-conalot');
          }
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo crear/editar el usuario | ' + e.message,
          life: 10000
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

::v-deep(.password  .p-inputtext) {
  width: 100% !important;
}

</style>